import * as z from "zod"

export const TieredCostModel = z.object({
  id: z.number().int(),
  created_at: z.date(),
  updated_at: z.date(),
  rate_id: z.number().int().nullish(),
  markup_id: z.number().int().nullish(),
  min: z.number().int().nullish(),
  max: z.number().int().nullish(),
  cost: z.number().int(),
})
