import * as z from "zod"
import { ShipmentStatus } from "@prisma/client"

export const TrackingShipmentModel = z.object({
  id: z.number().int(),
  created_at: z.date(),
  updated_at: z.date(),
  awb_number: z.string(),
  initial_piece_count: z.number().int().nullish(),
  initial_weight: z.number().nullish(),
  customer_ref: z.string().nullish(),
  next_update_at: z.date().nullish(),
  final_update_at: z.date(),
  disable_final_update_at_sync: z.boolean(),
  origin_airport_id: z.string(),
  destination_airport_id: z.string(),
  airline_id: z.string().nullish(),
  replaced_by_id: z.number().int().nullish(),
  request_id: z.number().int().nullish(),
  status: z.nativeEnum(ShipmentStatus),
})
