import * as z from "zod"

export const ShipmentSubscriberModel = z.object({
  id: z.number().int(),
  created_at: z.date(),
  updated_at: z.date(),
  preferred_name: z.string(),
  email: z.string(),
  unsubscribed_at: z.date().nullish(),
  unsubscribe_token: z.string(),
  shipment_id: z.number().int(),
  contact_id: z.number().int().nullish(),
})
