import * as z from "zod"
import { OnboardingStep } from "@prisma/client"

// Helper schema for JSON fields
type Literal = boolean | number | string
type Json = Literal | { [key: string]: Json } | Json[]
const literalSchema = z.union([z.string(), z.number(), z.boolean()])
const jsonSchema: z.ZodSchema<Json> = z.lazy(() => z.union([literalSchema, z.array(jsonSchema), z.record(jsonSchema)]))

export const UserModel = z.object({
  id: z.number().int(),
  created_at: z.date(),
  updated_at: z.date(),
  email: z.string(),
  supabase_user_id: z.string(),
  first_name: z.string().nullish(),
  last_name: z.string().nullish(),
  preferred_name: z.string().nullish(),
  full_name: z.string().nullish(),
  title: z.string().nullish(),
  phone_number: z.string().nullish(),
  hints: jsonSchema,
  email_verified_at: z.date().nullish(),
  is_admin: z.boolean(),
  onboarding_step: z.nativeEnum(OnboardingStep).nullish(),
  preferred_account_user_id: z.number().int().nullish(),
  contact_id: z.number().int().nullish(),
})
