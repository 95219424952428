import * as z from "zod"
import { EmailThreadType } from "@prisma/client"

export const EmailThreadModel = z.object({
  id: z.number().int(),
  created_at: z.date(),
  updated_at: z.date(),
  type: z.nativeEnum(EmailThreadType),
  count: z.number().int(),
  airline_id: z.string().nullish(),
  request_id: z.number().int(),
})
