import * as z from "zod"
import { BusinessType, AccountType } from "@prisma/client"

// Helper schema for JSON fields
type Literal = boolean | number | string
type Json = Literal | { [key: string]: Json } | Json[]
const literalSchema = z.union([z.string(), z.number(), z.boolean()])
const jsonSchema: z.ZodSchema<Json> = z.lazy(() => z.union([literalSchema, z.array(jsonSchema), z.record(jsonSchema)]))

export const AccountModel = z.object({
  id: z.number().int(),
  created_at: z.date(),
  updated_at: z.date(),
  name: z.string(),
  business_type: z.nativeEnum(BusinessType).nullish(),
  address: jsonSchema,
  iata_number: z.string().nullish(),
  is_iata_number_verified: z.boolean(),
  verified_at: z.date().nullish(),
  highlight_until: z.date().nullish(),
  type: z.nativeEnum(AccountType),
  stripe_customer_id: z.string().nullish(),
  is_open_to_domain_whitelist: z.boolean(),
  domain_whitelist: z.string().array(),
  airline_id: z.string().nullish(),
  parent_id: z.number().int().nullish(),
})
