import * as z from "zod"
import { DocumentType } from "@prisma/client"

export const DocumentModel = z.object({
  id: z.number().int(),
  created_at: z.date(),
  updated_at: z.date(),
  filename: z.string(),
  original_filename: z.string(),
  type: z.nativeEnum(DocumentType),
  request_id: z.number().int().nullish(),
  account_id: z.number().int().nullish(),
})
