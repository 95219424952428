import * as z from "zod"
import { AccountWebhookEvent } from "@prisma/client"

export const AccountWebhookModel = z.object({
  id: z.number().int(),
  created_at: z.date(),
  updated_at: z.date(),
  account_id: z.number().int(),
  event: z.nativeEnum(AccountWebhookEvent),
  uri: z.string(),
})
