import * as z from "zod"

export const ShipmentEventModel = z.object({
  id: z.number().int(),
  created_at: z.date(),
  updated_at: z.date(),
  flight_number: z.string(),
  piece_count: z.number().int().nullish(),
  weight: z.number().nullish(),
  occurred_at: z.date(),
  raw_type: z.string(),
  raw_airport_id: z.string().nullish(),
  shipment_id: z.number().int(),
})
