import * as z from "zod"
import { QuoteStatus, TimeOfDay, EarliestTenderOption, BacklogStatus } from "@prisma/client"

// Helper schema for JSON fields
type Literal = boolean | number | string
type Json = Literal | { [key: string]: Json } | Json[]
const literalSchema = z.union([z.string(), z.number(), z.boolean()])
const jsonSchema: z.ZodSchema<Json> = z.lazy(() => z.union([literalSchema, z.array(jsonSchema), z.record(jsonSchema)]))

export const QuoteModel = z.object({
  id: z.number().int(),
  created_at: z.date(),
  updated_at: z.date(),
  status: z.nativeEnum(QuoteStatus),
  status_last_updated_at: z.date().nullish(),
  dropoff_at: z.date().nullish(),
  expires_at: z.date().nullish(),
  arrives_at: z.date().nullish(),
  cutoff_at: z.date().nullish(),
  tender_date: z.date().nullish(),
  tender_date_time_of_day: z.nativeEnum(TimeOfDay).nullish(),
  earliest_tender: z.nativeEnum(EarliestTenderOption).nullish(),
  estimated_transit_time: z.string().nullish(),
  selected_service_type: z.string().nullish(),
  chargeable_weight: z.number().nullish(),
  is_fastest: z.boolean().nullish(),
  delivery_address: jsonSchema,
  booking_instructions: z.string().nullish(),
  dropoff_instructions: z.string().nullish(),
  additional_information: z.string().nullish(),
  backlog_status: z.nativeEnum(BacklogStatus),
  backlogged_until: z.date().nullish(),
  airline_id: z.string().nullish(),
  dropoff_contact_id: z.number().int().nullish(),
  request_id: z.number().int(),
  sales_contact_id: z.number().int().nullish(),
  origin_airport_id: z.string().nullish(),
  destination_airport_id: z.string().nullish(),
  fulfillment_partner_configuration_id: z.number().int().nullish(),
})
