import * as z from "zod"
import { LineItemType, PerUnitType } from "@prisma/client"

export const LineItemModel = z.object({
  id: z.number().int(),
  created_at: z.date(),
  updated_at: z.date(),
  label: z.string(),
  description: z.string().nullish(),
  type: z.nativeEnum(LineItemType).nullish(),
  cost: z.number().int(),
  is_per_unit: z.boolean(),
  per_unit_value: z.number().int().nullish(),
  per_unit_type: z.nativeEnum(PerUnitType),
  chargeable_weight: z.number().nullish(),
  quote_variant_id: z.number().int(),
  issued_by_rate_id: z.number().int().nullish(),
  issued_by_tiered_cost_id: z.number().int().nullish(),
  issued_by_surcharge_id: z.number().int().nullish(),
  disassociated_at: z.date().nullish(),
  parent_id: z.number().int().nullish(),
  issued_by_markup_id: z.number().int().nullish(),
})
