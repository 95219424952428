import * as z from "zod"
import { AircraftType } from "@prisma/client"

// Helper schema for JSON fields
type Literal = boolean | number | string
type Json = Literal | { [key: string]: Json } | Json[]
const literalSchema = z.union([z.string(), z.number(), z.boolean()])
const jsonSchema: z.ZodSchema<Json> = z.lazy(() => z.union([literalSchema, z.array(jsonSchema), z.record(jsonSchema)]))

export const InstructionModel = z.object({
  id: z.number().int(),
  created_at: z.date(),
  updated_at: z.date(),
  name: z.string().nullish(),
  delivery_address: jsonSchema,
  booking_instructions: z.string().nullish(),
  dropoff_instructions: z.string().nullish(),
  aircraft_type: z.nativeEnum(AircraftType).nullish(),
  airline_id: z.string(),
  airport_id: z.string(),
})
