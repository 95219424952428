import * as z from "zod"

export const VerificationCodeModel = z.object({
  id: z.number().int(),
  created_at: z.date(),
  updated_at: z.date(),
  code: z.string(),
  expires_at: z.date(),
  user_id: z.number().int(),
})
