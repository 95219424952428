import * as z from "zod"
import { RuleType, RateCategory, SurchargeCategory, SurchargeWeightType, HandlingOptions, CargoType, AircraftType } from "@prisma/client"

export const RuleModel = z.object({
  id: z.number().int(),
  created_at: z.date(),
  updated_at: z.date(),
  airline_id: z.string().nullish(),
  account_id: z.number().int().nullish(),
  type: z.nativeEnum(RuleType),
  required_rate_categories: z.nativeEnum(RateCategory).array(),
  required_surcharge_categories: z.nativeEnum(SurchargeCategory).array(),
  required_surcharge_weight_type: z.nativeEnum(SurchargeWeightType).nullish(),
  chargeable_weight_max: z.number().int().nullish(),
  length_max: z.number().int().nullish(),
  width_max: z.number().int().nullish(),
  height_max: z.number().int().nullish(),
  required_handling_types: z.nativeEnum(HandlingOptions).array(),
  required_cargo_types: z.nativeEnum(CargoType).array(),
  must_have_all_line_items_filled: z.boolean().nullish(),
  must_be_known_shipper: z.boolean().nullish(),
  must_be_verified_account: z.boolean().nullish(),
  min_num_quote_variants: z.number().int().nullish(),
  must_have_supported_account_type: z.boolean(),
  aircraft_type_sequence: z.nativeEnum(AircraftType).array(),
  ett_segment_increase: z.number().int().nullish(),
  hours_of_validity: z.number().int().nullish(),
})
