import { z, ZodFirstPartySchemaTypes } from 'zod';

export const requiredZodString = (field: string) =>
  z
    .string({
      required_error: `${field} is required`,
      invalid_type_error: `${field} is required`,
    })
    .min(1, `${field} is required`);

export const getRealType = (type): [ZodFirstPartySchemaTypes['_type'], ZodFirstPartySchemaTypes['_def']] => {
  const typeName = type._def.typeName;
  switch (typeName) {
    case 'ZodNullable':
    case 'ZodOptional':
      return getRealType(type._def.innerType);
    default:
      return [typeName, type._def];
  }
};
