import * as z from "zod"
import { AircraftType } from "@prisma/client"

export const FlightSegmentModel = z.object({
  id: z.number().int(),
  created_at: z.date(),
  updated_at: z.date(),
  arrives_at: z.date().nullish(),
  departs_at: z.date().nullish(),
  flight_number: z.string().nullish(),
  aircraft_type: z.nativeEnum(AircraftType).nullish(),
  aircraft_id: z.number().int().nullish(),
  airline_id: z.string().nullish(),
  destination_airport_id: z.string().nullish(),
  origin_airport_id: z.string().nullish(),
  quote_id: z.number().int().nullish(),
  rate_id: z.number().int().nullish(),
  flight_path_position: z.number().int().nullish(),
  is_final_destination: z.boolean().nullish(),
  max_kg_per_piece: z.number().nullish(),
  max_cm_length: z.number().nullish(),
  max_cm_width: z.number().nullish(),
  max_cm_height: z.number().nullish(),
  smallest_max_cm: z.number().nullish(),
  biggest_max_cm: z.number().nullish(),
})
