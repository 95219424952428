export type ENVS = 'dev' | 'stg' | 'prd';

export type Nullable<T> = {
  [P in keyof T]: T[P] | null;
};

export type RequireOnly<T, K extends keyof T> = {
  [Key in K]: T[Key];
} & {
  [Key in Exclude<keyof T, K>]?: T[Key];
};

export type ValueOf<T> = T[keyof T];

export type KeysOfUnion<T> = T extends T ? keyof T : never;

interface HasId {
  id: string | number;
}

export type Shell<T extends HasId> = RequireOnly<T, 'id'>;

export type AirlineAccount = {
  id: number;
  created_at: Date;
  updated_at: Date;

  airline: Airline;
  airline_id: string;

  account: Account;
  account_id: number;
};

export type Airline = {
  id: string;
  created_at: Date;
  updated_at: Date;
  aircrafts: Aircraft;
  airports: Airport[];
  awb_prefix?: string;
  contacts: Contact[];
  description?: string;
  flight_segments: FlightSegment;
  imports: RateImport;
  instructions: Instruction[];
  is_active: boolean;
  logo_uri?: string;
  name: string;
  quotes: Quote[];
  rates: Rate;
  surcharges: Surcharge;
};

export type Airport = {
  id: string;
  created_at: Date;
  updated_at: Date;
  address: JSON;
  airlines: Airline;
  as_flight_segment_destination: FlightSegment;
  as_flight_segment_origin: FlightSegment;
  as_request_destination: AirportRequest[];
  as_request_origin: AirportRequest[];
  as_surcharge_destination: AirportSurcharge[];
  as_surcharge_origin: AirportSurcharge[];
  can_arrive: boolean;
  can_depart: boolean;
  contacts: Contact[];
  instructions: Instruction[];
  latlng: number;
  name?: string;
  quote_destination_airport: Quote[];
  quote_origin_airport: Quote[];
  timeZone: string;
};

export type AirportRequest = {
  id: number;
  created_at: Date;
  updated_at: Date;
  airport_as_destination?: Airport;
  airport_as_destination_id?: string;
  airport_as_origin?: Airport;
  airport_as_origin_id?: string;
  request_for_destination?: Request;
  request_for_destination_id?: number;
  request_for_origin?: Request;
  request_for_origin_id?: number;
};

export type AirportSurcharge = {
  id: number;
  created_at: Date;
  updated_at: Date;
  airport_as_destination?: Airport;
  airport_as_destination_id?: string;
  airport_as_origin?: Airport;
  airport_as_origin_id?: string;
  surcharge_for_destination?: Surcharge;
  surcharge_for_destination_id?: number;
  surcharge_for_origin?: Surcharge;
  surcharge_for_origin_id?: number;
};

export type Aircraft = {
  id: number;
  created_at: Date;
  updated_at: Date;
  airlines: Airline;
  flight_segments: FlightSegment;
  max_height?: number;
  max_length?: number;
  max_weight?: number;
  max_width?: number;
  name: string;
  type?: AircraftType;
};

export type Account = {
  id: number;
  created_at: Date;
  updated_at: Date;
  account_invites: AccountInvite;
  address?: JSON;
  business_type: string;
  contacts: Contact[];
  iata_number?: string;
  name: string;
  rates: Rate;
  requests: Request[];
  users: AccountUser;
  type: AccountType;
};

export type AccountUser = {
  id: number;
  created_at: Date;
  updated_at: Date;
  authored_requests: Request[];
  account: Account;
  account_id: number;
  user_id: string;
  user: User;
};

export type AccountInvite = {
  id: number;
  created_at: Date;
  updated_at: Date;
  accepted_at?: Date;
  account: Account;
  account_id: number;
  email: string;
  token: string;
};

export type Document = {
  id: number;
  created_at: Date;
  updated_at: Date;
  filename: string;
  original_filename: string;
  type: DocumentType;
  request_id: number;
  account_id: number;
};

export type Quote = {
  id: number;
  additional_information?: string;
  airline?: Airline;
  airline_id?: string;
  arrives_at?: Date;
  backlog_status: BacklogStatus;
  backlogged_until?: Date;
  booking_instructions?: string;
  chargeable_weight?: number;
  created_at: Date;
  cutoff_at?: Date;
  delivery_address?: JSON;
  destination_airport?: Airport;
  destination_airport_id?: string;
  dropoff_at?: Date;
  dropoff_contact?: Contact;
  dropoff_contact_id?: number;
  dropoff_instructions?: string;
  estimated_transit_time?: EstimatedTransitTime;
  expires_at?: Date;
  flight_path: FlightSegment[];
  is_fastest?: boolean;
  origin_airport?: Airport;
  origin_airport_id?: string;
  quote_variants: QuoteVariant[];
  request: Request;
  request_id: number;
  sales_contact?: Contact;
  sales_contact_id?: number;
  selected_service_type?: string;
  status: QuoteStatus;
  tender_date?: Date;
  updated_at: Date;
};

export type QuoteVariant = {
  id: number;
  created_at: Date;
  updated_at: Date;
  line_items: LineItem[];
  name: string;
  quote: Quote;
  quote_id: number;
  request?: Request;
  type: VariantType;
};

export type LineItem = {
  id: number;
  created_at: Date;
  updated_at: Date;
  cost: number;
  description?: string;
  disassociated_at?: Date;
  is_per_unit: boolean;
  type: LineItemType;
  issued_by_rate?: Rate;
  issued_by_rate_id?: number;
  issued_by_surcharge?: Surcharge;
  issued_by_surcharge_id?: number;
  issued_by_tiered_cost?: TieredCost;
  issued_by_tiered_cost_id?: number;
  label: string;
  per_unit_value?: number;
  chargeable_weight?: number;
  quote_variant: QuoteVariant;
  quote_variant_id: number;
};

export type Instruction = {
  id: number;
  created_at: Date;
  updated_at: Date;
  airline: Airline;
  airline_id: string;
  airport: Airport;
  airport_id: string;
  booking_instructions?: string;
  delivery_address?: JSON;
  dropoff_instructions?: string;
  name?: string;
};

export type Contact = {
  id: number;
  created_at: Date;
  updated_at: Date;
  account?: Account;
  account_id?: number;
  airline?: Airline;
  airline_id?: string;
  airport?: Airport;
  airport_id?: string;
  as_quote_dropoff_contact: Quote[];
  as_quote_sales_contact: Quote[];
  email?: string;
  preferred_name: string;
  full_name: string;
  notes?: string;
  phone_number?: string;
  requests_as_default_sender: Request[];
  tags: string[];
  title?: string;
};

export type Cargo = {
  id: number;
  created_at: Date;
  updated_at: Date;
  handling: HandlingOptions[];
  height: number;
  length: number;
  quantity: number;
  request: Request;
  request_id: number;
  type: CargoType;
  weight: number;
  width: number;
};

export type FlightSegment = {
  aircraft?: Aircraft;
  aircraft_id?: number;
  aircraft_type?: AircraftType;
  airline?: Airline;
  airline_id?: string;
  arrives_at?: Date;
  created_at: Date;
  departs_at?: Date;
  destination_airport?: Airport;
  destination_airport_id?: string;
  airport_as_origin_id?: string;
  airport_as_destination_id?: string;
  flight_number?: string;
  flight_path_position?: number;
  id: number;
  is_final_destination?: boolean;
  max_cm_height?: number;
  max_cm_length?: number;
  max_cm_width?: number;
  max_kg_per_piece?: number;
  origin_airport?: Airport;
  origin_airport_id?: string;
  quote?: Quote;
  quote_id?: number;
  rate?: Rate;
  rate_id?: number;
  updated_at: Date;
};

export type Rate = {
  account?: Account;
  account_id?: number;
  airline: Airline;
  airline_id: string;
  airline_product?: string;
  airline_service?: string;
  cargo_type: CargoType;
  comments?: string;
  costs: TieredCost;
  created_at: Date;
  expires_at?: Date;
  expiry_comment?: string;
  expiry_reason?: ExpiryReason;
  hash?: string;
  id: number;
  import?: RateImport;
  import_id?: number;
  line_items: LineItem;
  min_cost?: number;
  rate_category: RateCategory;
  replaced_by?: Rate;
  replaced_by_id?: number;
  replacement_initiated_at?: Date;
  replaces?: Rate;
  route: FlightSegment;
  service_type: ServiceType;
  starts_at: Date;
  surcharge_categories: SurchargeCategory;
  updated_at: Date;
  name?: string;
  earliest_expiry?: Date;
};

export type TieredCost = {
  cost: number;
  created_at: Date;
  id: number;
  max?: number;
  min?: number;
  rate: Rate;
  rate_id: number;
  updated_at: Date;
};

export type RateImport = {
  id: number;
  created_at: Date;
  updated_at: Date;
  airline_id: string;
  source_type: SourceType;
  source?: string;
  source_archive: string;
  name?: string;
  earliest_expiry?: Date;
  state: RateImportState;
  supabase_user_id: string;

  airline: Airline;
  rates: Rate;
};

export type Surcharge = {
  airline: Airline;
  airline_code?: string;
  airline_id: string;
  airline_label?: string;
  airline_product?: string;
  account_id?: number;
  cargo_type?: CargoType;
  comments?: string;
  created_at: Date;
  destination_airports: AirportSurcharge[];
  expires_at?: Date;
  id: number;
  is_optional: boolean;
  min_cost?: number;
  origin_airports: AirportSurcharge[];
  per_unit_cost?: number;
  replaced_by?: Surcharge;
  replaced_by_id?: number;
  replaces?: Surcharge;
  service_type?: ServiceType;
  starts_at: Date;
  surcharge_category: SurchargeCategory;
  unit: SurchargeUnit;
  updated_at: Date;
  weight_type: SurchargeWeightType;
};

export type Request = {
  account?: Account;
  account_id?: number;
  account_ref?: string;
  additional_information?: string;
  aircraft_types: AircraftType[];
  arrive_by?: Date;
  author?: AccountUser;
  author_id?: number;
  awb_number?: string;
  booked_quote_variant?: QuoteVariant;
  booked_quote_variant_id?: number;
  cargo: Cargo;
  cargo_cooling?: CargoCooling;
  cargo_details?: string;
  cargo_origin?: JSON;
  cargo_temperature_range?: CargoTemperatureRange;
  chargeable_weight?: number;
  commodity?: string;
  confirmed_at?: Date;
  created_at: Date;
  default_sender_contact?: Contact;
  default_sender_contact_id?: number;
  destination_airports: AirportRequest[];
  dropoff_by?: Date;
  customer_ref?: string;
  freight_status?: FreightStatus;
  gross_weight?: number;
  id: number;
  is_known_shipper?: boolean;
  is_pre_screened?: boolean;
  is_subscribed: boolean;
  origin_airports: AirportRequest[];
  original_dimension_unit: DimensionUnit;
  original_weight_unit: WeightUnit;
  quotes: Quote[];
  requires_direct_flight?: boolean;
  service_type: string[];
  status: RequestStatus;
  target_rate?: number;
  un_number?: number;
  class?: number;
  packing_group?: PackingGroup;
  aircraft_variant?: AircraftVariant;
  packing_instruction?: PackingInstruction;
  section?: Section;
  battery_type?: BatteryType;
  updated_at: Date;
  volumetric_weight?: number;
  main_request?: Request;
  main_request_id?: number;
  linked_requests?: Request[];
};

export type User = {
  id: number;
  created_at: Date;
  updated_at: Date;
  preferred_name: string;
  full_name: string;
  email: string;
  phone_number: string;
  title: string;
  supabase_user_id: string;
  is_admin: boolean;
  hints: JSON;
  onboarding_step: OnboardingStep;
  accounts: Account[];
  account_users: AccountUser[];
};

// Typesense

export type TSAirport = {
  id: string;
  airport_name: string;
  airport_time_zone: string;
  airport_id: string;
  airport_latlng: [number, number];
  airport_main_gateway: boolean;
  airport_country: string;
};

export type TSAccount = {
  id: string;
  account_id: string;
  name: string;
  highlight_until: number;
};

export type TSAirline = {
  id: string;
  airline_name: string;
  airline_id: string;
};

export type TSAircraft = {
  id: string;
  aircraft_name: string;
  aircraft_type: string;
};

export type TSContact = {
  id: string;
  created_at: number;
  updated_at?: number;
  preferred_name?: string;
  full_name?: string;
  email: string;
  phone?: string;
  associations: {
    tags?: string[];
    accounts?: RequireOnly<Account, 'id' | 'name'>[];
    airlines?: string[];
    airports?: string[];
    has_airports: boolean;
    has_accounts: boolean;
    has_tags: boolean;
  }[];
};

export type TSRequest = {
  id: string;
  formatted_id: string;
  account_id: string;
  request_status: string;
  origins: string[];
  destinations: string[];
  author_email: string;
  created_at: number;
  dropoff_by: number;
  is_known_shipper: boolean;
  is_freighter_required: boolean;
  cargo_type: string;
  quote_status_counts: Record<QuoteStatus, number>;
  color?: string;
  classifications?: { name: string; color: string }[];
  main_request_id: string;
  linked_request_ids: string[];
};

// Enums
export enum ExpiryReason {
  SUCCEEDED = 'SUCCEEDED',
  ROUTE_ENDED = 'ROUTE_ENDED',
  INVALID_ENTRY = 'INVALID_ENTRY',
}

export enum SurchargeUnit {
  KG = 'KG',
  AWB = 'AWB',
  UN = 'UN',
  FLAT = 'FLAT',
}

export enum SurchargeCategory {
  FUEL = 'FUEL',
  SECURITY = 'SECURITY',
  SCREENING = 'SCREENING',
  NON_EAWB = 'NON_EAWB',
  FWL_FHL_EDI = 'FWL_FHL_EDI',
  UN_FEE = 'UN_FEE',
  TRUCKING = 'TRUCKING',
  LI_BATTERY = 'LI_BATTERY',
  DRY_ICE = 'DRY_ICE',
  NON_STACKABLE = 'NON_STACKABLE',
  UNKNOWN_SHIPPER = 'UNKNOWN_SHIPPER',
}

export enum MarkupCategory {
  FP_SERVICE = 'FP_SERVICE',
  OTHER_MARKUP = 'OTHER_MARKUP',
}

export enum SurchargeWeightType {
  CHARGEABLE_WEIGHT = 'CHARGEABLE_WEIGHT',
  GROSS_WEIGHT = 'GROSS_WEIGHT',
}

export enum ServiceType {
  General = 'General',
  Expedited = 'Expedited',
  Urgent = 'Urgent',
  Other = 'Other',
}

export enum VariantType {
  General = 'General',
  Expedited = 'Expedited',
  Urgent = 'Urgent',
  Other = 'Other',
}

export enum RateCategory {
  TARIFF = 'TARIFF',
  CONTRACT = 'CONTRACT',
  PROMO = 'PROMO',
  SPOT = 'SPOT',
}

export enum LineItemType {
  // From RateCategory
  TARIFF = 'TARIFF',
  CONTRACT = 'CONTRACT',
  PROMO = 'PROMO',
  SPOT = 'SPOT',

  // From SurchargeCategory
  FUEL = 'FUEL',
  SECURITY = 'SECURITY',
  SCREENING = 'SCREENING',
  NON_EAWB = 'NON_EAWB',
  FWL_FHL_EDI = 'FWL_FHL_EDI',
  UN_FEE = 'UN_FEE',
  TRUCKING = 'TRUCKING',
  LI_BATTERY = 'LI_BATTERY',
  DRY_ICE = 'DRY_ICE',
  NON_STACKABLE = 'NON_STACKABLE',
  UNKNOWN_SHIPPER = 'UNKNOWN_SHIPPER',

  // From MarkupCategory
  FP_SERVICE = 'FP_SERVICE',
  OTHER_MARKUP = 'OTHER_MARKUP',

  // Other
  OTHER = 'OTHER',
}

export enum QuoteStatus {
  Pending = 'Pending',
  SentToCarrier = 'SentToCarrier',
  ReadyForQC = 'ReadyForQC',
  InProgress = 'InProgress',
  Confirmed = 'Confirmed',
  Expired = 'Expired',
}

export enum RequestStatus {
  New = 'New',
  QuotingInProgress = 'QuotingInProgress',
  QuotingPending = 'QuotingPending',
  QuotingComplete = 'QuotingComplete',
  BookingRequested = 'BookingRequested',
  BookingPending = 'BookingPending',
  BookingChange = 'BookingChange',
  BookingCancel = 'BookingCancel',
  BookingComplete = 'BookingComplete',
  InfoPending = 'InfoPending',
  Cancelled = 'Cancelled',
}

export enum FreightStatus {
  Quoting = 'Quoting',
  Confirmed = 'Confirmed',
}

export enum HandlingOptions {
  Stackable = 'Stackable',
  NonStackable = 'NonStackable',
  Drums = 'Drums',
  Pails = 'Pails',
  Supersacks = 'Supersacks',
  // Deprecated
  TopLoadOnly = 'TopLoadOnly',
  RequiredOrientation = 'RequiredOrientation',
  Fragile = 'Fragile',
}

export enum DimensionUnit {
  cm = 'cm',
  in = 'in',
}

export enum WeightUnit {
  kg = 'kg',
  lb = 'lb',
}

export enum CargoType {
  GeneralCargo = 'GeneralCargo',
  Perishable = 'Perishable',
  DangerousGoods = 'DangerousGoods',
  Pharma = 'Pharma',
  Valuable = 'Valuable',
  // TODO: this isn't actually part of CargoType, but it's used in the UI
  Batteries = 'Batteries',
}

export enum PerUnitType {
  CURRENCY = 'CURRENCY',
  PERCENTAGE = 'PERCENTAGE',
}

export enum AircraftType {
  Freighter = 'Freighter',
  WideBody = 'WideBody',
  NarrowBody = 'NarrowBody',
  RFS = 'RFS',
}

export enum BacklogStatus {
  None = 'None',
  AtRisk = 'AtRisk',
  Backlogged = 'Backlogged',
}

export enum SourceType {
  GOOGLE_SHEETS = 'GOOGLE_SHEETS',
}

export enum RateImportState {
  PENDING = 'PENDING',
  RUNNING = 'RUNNING',
  ERROR = 'ERROR',
  SUCCESS = 'SUCCESS',
  EXPIRED = 'EXPIRED',
}

export enum CargoTemperatureRange {
  TWO_TO_EIGHT_CELSIUS = 'TWO_TO_EIGHT_CELSIUS',
  TWO_TO_TWENTY_FIVE_CELSIUS = 'TWO_TO_TWENTY_FIVE_CELSIUS',
  FIFTEEN_TO_TWENTY_FIVE_CELSIUS = 'FIFTEEN_TO_TWENTY_FIVE_CELSIUS',
}

export enum CargoCooling {
  GEL_PACKS = 'GEL_PACKS',
  DRY_ICE = 'DRY_ICE',
  NO_COOLING = 'NO_COOLING',
}

export enum DocumentType {
  DGD = 'DGD',
  MSDS = 'MSDS',
  AIRWAY_BILL = 'AIRWAY_BILL',
}

export enum AircraftVariant {
  CAO = 'CAO',
  PAX = 'PAX',
}

export enum PackingGroup {
  I = 'I',
  II = 'II',
  III = 'III',
}

export enum PackingInstruction {
  PI965 = 'PI965',
  PI966 = 'PI966',
  PI967 = 'PI967',
  PI968 = 'PI968',
  PI969 = 'PI969',
  PI970 = 'PI970',
}

export enum Section {
  I = 'I',
  II = 'II',
  IA = 'IA',
  IB = 'IB',
}

export enum BatteryType {
  DG_BATTERIES_IN_EQUIPMENT = 'DG_BATTERIES_IN_EQUIPMENT',
  NON_RESTRICTED_BATTERIES_IN_EQUIPMENT = 'NON_RESTRICTED_BATTERIES_IN_EQUIPMENT',
}

export enum BatteryTypeVariant {
  LiIon_UN3481_PI967 = 'LiIon_UN3481_PI967',
  LiIon_UN3481_PI966 = 'LiIon_UN3481_PI966',
  LiMetal_UN3091_PI969 = 'LiMetal_UN3091_PI969',
  LiMetal_UN3091_PI970 = 'LiMetal_UN3091_PI970',
}

export enum AccountType {
  IATA = 'IATA',
  IAC = 'IAC',
  UNCERTIFIED = 'UNCERTIFIED',
  ORGANIZATION = 'ORGANIZATION',
}

export enum BusinessType {
  FREIGHT_FORWARDER = 'FREIGHT_FORWARDER',
  GSA = 'GSA',
  AIRLINE = 'AIRLINE',
}

export enum ShipmentStatus {
  ACTIVE = 'ACTIVE',
  REPLACED = 'REPLACED',
  COMPLETED = 'COMPLETED',
}

export enum TimeOfDay {
  MORNING = 'MORNING',
  MID_DAY = 'MID_DAY',
  EVENING = 'EVENING',
}

export enum EarliestTenderOption {
  ANYTIME = 'ANYTIME',
  TWENTY_FOUR_HOURS = 'TWENTY_FOUR_HOURS',
  FOURTY_EIGHT_HOURS = 'FOURTY_EIGHT_HOURS',
  SEVENTY_TWO_HOURS = 'SEVENTY_TWO_HOURS',
}

export enum OnboardingStep {
  CONFIRM = 'CONFIRM',
  PERSONAL = 'PERSONAL',
  COMPANY = 'COMPANY',
  COMPANY_SELECT = 'COMPANY_SELECT',
  SIGNATURE = 'SIGNATURE',
  INVITE = 'INVITE',
  COMPLETE = 'COMPLETE',
}

// Manually Created Types

export type Address = {
  // Main address
  line_one?: string;
  // Apt/building number
  line_two?: string;
  // Country name (i.e. Canada, Germany, etc.)
  country?: string;
  // Province or State name (i.e. BC, Washington, etc.)
  state?: string;
  // City name (i.e. Victoria, Kelowna, etc.)
  city?: string;
  // Zip or postal code (i.e. V8X0B7, 10295, etc.)
  zip_code?: string;
};

export enum EstimatedTransitTime {
  OneToTwoDays = '1-2 days',
  TwoToThreeDays = '2-3 days',
  ThreeToFourDays = '3-4 days',
  FourToFiveDays = '4-5 days',
  FiveToSixDays = '5-6 days',
  SixToSevenDays = '6-7 days',
  SevenDaysPlus = '7+ days',
}

export enum ClarificationReason {
  IncorrectDetails = 'IncorrectDetails',
  Oversize = 'Oversize',
  DangerousGoods = 'DangerousGoods',
  TemperatureControl = 'TemperatureControl',
}

export enum CancelReason {
  CargoDelay = 'CargoDelay',
  TransitTime = 'TransitTime',
  Rate = 'Rate',
  Unknown = 'Unknown',
}

export type SelectOption = {
  label: string;
  value: string;
};

export type MaxAircraftParams = {
  length: number;
  width: number;
  height: number;
  weight_per_piece: number;
};

export type Hint = {
  name: string;
  displayed_at: Date | null;
  content: {
    title: string;
    description: string;
  };
};

export enum RouteEditorAction {
  Unset = 'Unset',
  EndRoute = 'EndRoute',
  Invalidate = 'Invalidate',
  AirlineServiceChange = 'AirlineServiceChange',
  ErrorFix = 'ErrorFix',
}

export enum EmailTemplateName {
  BOOKING_CONFIRM_TO_FF = 'booking-confirm-to-ff.mjml',
  GENERIC_EMAIL_TO_AIRLINE = 'generic-email-to-airline.mjml',
  TRACKING_SHIPMENT_DAILY_UPDATE = 'track-and-trace.mjml',
  TRACKING_SHIPMENT_DROPOFF_REMINDER = 'dropoff-reminder.mjml',
  BOOKING_REQUEST_TO_AIRLINE = 'airline-booking.mjml',
  FORGOT_PASSWORD = 'forgot-password.mjml',
  INVITE_USER = 'invite-user.mjml',
  QUOTE_REQUEST_NOTI = 'quote-request-notification-email.mjml',
  QUOTE_REQUEST_TO_AIRLINE = 'airline-quoting.mjml',
  QUOTE_REQUEST_TO_FF = 'quote-request-to-ff.mjml',
  QUOTE_REQUEST_TO_FF_MISSING_INFORMATION = 'quote-request-to-ff-missing-information.mjml',
  QUOTE_UPDATE_TO_FF = 'quote-update-to-ff.mjml',
  QUOTE_REQUEST_CLARIFICATION_TO_FF = 'quote-request-clarification-to-ff.mjml',
  WELCOME_EMAIL = 'welcome-email.mjml',
  VERIFY_EMAIL = 'verify-email.mjml',
}

export enum QuoteStage {
  ZERO = 'ZERO',
  ONE = 'ONE',
  TWO = 'TWO',
  THREE = 'THREE',
  FOUR = 'FOUR',
}

export enum DrawerType {
  AirlineQuoting = 'AirlineQuoting',
  QuoteUpdate = 'QuoteUpdate',
  AirlineBooking = 'AirlineBooking',
  BookingConfirmation = 'BookingConfirmation',
  BookingChange = 'BookingChange',
  BookingCancel = 'BookingCancel',
  NewRate = 'NewRate',
  Contact = 'Contact',
  RequestClarifications = 'RequestClarifications',
}

export enum NotificationCategory {
  Quote = 'Quote',
  Booking = 'Booking',
}

export type FormType = 'Create' | 'Update';
