import * as z from "zod"

export const ApiKeyModel = z.object({
  id: z.number().int(),
  created_at: z.date(),
  updated_at: z.date(),
  account_id: z.number().int(),
  secret_key: z.string(),
  default_account_user_id: z.number().int(),
  description: z.string().nullish(),
  invalidated_at: z.date().nullish(),
})
