import { Button, Card, CardBody, CardFooter, Container, Stack } from '@chakra-ui/react';
import { useDisclosure } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Cargo, CargoType, DimensionUnit, NotificationCategory, TimeOfDay, WeightUnit } from '@pelicargo/types';
import { useError } from '@pelicargo/ui';
import { getPrettyTimeOfDay, prettyDate, removeDash } from '@pelicargo/utils';
import { uniqBy } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { HiChevronLeft } from 'react-icons/hi';
import { HiShare } from 'react-icons/hi2';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';

import { BookingForm } from '../../components/BookingForm';
import { DefaultContactsCard } from '../../components/DefaultContactsCard';
import { FlightSegmentsCard } from '../../components/FlightSegmentsCard';
import { LinkedRequestModal } from '../../components/LinkedRequestModal';
import { Page } from '../../components/Page';
import { PriceCardTabs } from '../../components/PriceCardTabs';
import { RequestDetailsCard } from '../../components/RequestDetailsCard';
import { SimpleForm } from '../../components/SimpleForm';
import { trpc } from '../../config/trpc';
import { useTargetAccount } from '../../hooks/useTargetAccount';
import { API } from '../../utils/api-typings';
import { CONTAINER_2XL } from '../../utils/constants';

type Params = {
  quoteId?: string;
};

type FormValues = {
  awb_number: string;
  tender_date: string;
  tender_date_time_of_day: string;
  is_pre_screened?: boolean;
  customer_ref: string;
  additional_information: string;
  terms: boolean;
  documents: any[];
  default_contacts: {
    contact_id: string;
    updates_for: API.NotificationCategory[];
  }[];
};

const schema = Yup.object({
  terms: Yup.boolean().oneOf([true], 'Please accept the terms and conditions'),
  awb_number: Yup.string()
    .nullable()
    .test('awb-number-length', 'AWB number must be exactly 11 characters', (value) => {
      const cleanedValue = removeDash(value);
      // Check if the field is empty or has only the pre-filled digits
      if (!cleanedValue || cleanedValue?.length === 3) return true;
      // Check if the length is exactly 11 characters
      return cleanedValue?.length === 11;
    }),
  tender_date: Yup.date().required('Tender date is required'),
  customer_ref: Yup.string().optional(),
  is_pre_screened: Yup.boolean().optional(),
  additional_information: Yup.string(),
  documents: Yup.array().when('cargoType', {
    is: CargoType.DangerousGoods,
    then: () => Yup.array().min(1, 'Please upload a document'),
  }),
  default_contacts: Yup.array().of(
    Yup.object().shape({
      contact_id: Yup.string().required('Contact is required'),
      updates_for: Yup.array().of(Yup.string().optional()).default([]),
    }),
  ),
});

export const BookQuote = () => {
  const { handleError } = useError();
  const navigate = useNavigate();
  const location = useLocation();
  const params: Params = useParams();
  const { targetAccount } = useTargetAccount();

  const linkedRequestModal = useDisclosure();

  const { data: quote } = trpc.getQuote.useQuery({ id: Number(params?.quoteId) });

  const { data: requestDefaultContacts } = trpc.getDefaultContactsByRequest.useQuery(
    {
      id: quote?.request_id,
      updates_for: API.NotificationCategory.Booking,
    },
    { enabled: !!quote?.request_id },
  );
  const { data: accountDefaultContacts } = trpc.getDefaultContactsByAccount.useQuery(
    {
      id: targetAccount?.id,
      updates_for: API.NotificationCategory.Booking,
    },
    { enabled: !!targetAccount?.id },
  );
  // Unique contacts by contact_id prioritizing request contacts
  const default_contacts = uniqBy([...(requestDefaultContacts || []), ...(accountDefaultContacts || [])], 'contact_id');

  const bookQuote = trpc.bookQuote.useMutation();

  const methods = useForm<FormValues>({
    mode: 'onSubmit',
    resolver: yupResolver(schema) as any,
  });

  useEffect(() => {
    const request = quote?.request;
    const customer_ref = request?.customer_ref;
    const awb_number = request?.awb_number;
    const documents = request?.documents;
    methods.reset({
      ...methods.getValues(),
      customer_ref: customer_ref ?? '',
      awb_number: awb_number ?? '',
      documents: documents ?? [],
      is_pre_screened: false,
    });
  }, [methods, quote?.request]);

  useEffect(() => {
    if (default_contacts?.length) {
      const contacts = default_contacts.map((contact) => ({
        contact_id: String(contact.contact_id),
        updates_for: contact.updates_for,
      }));
      methods.setValue(
        'default_contacts',
        contacts.map((contact) => ({
          contact_id: contact.contact_id,
          updates_for: contact.updates_for as API.NotificationCategory[],
        })),
      );
    }
  }, [default_contacts?.length, methods]);

  const [tabIndex, setTabIndex] = useState(location?.state?.quoteVariantIndex || 0);

  const quoteVariant = quote?.quote_variants?.[tabIndex];

  const handleTabsChange = (index: number) => {
    setTabIndex(index);
  };

  const trimmedFlightSegments = useMemo(() => {
    const segments = [];

    quote?.flight_path?.forEach((node) => {
      segments.push({
        origin: node?.origin_airport_id,
        destination: node?.destination_airport_id,
        aircraftType: node?.aircraft_type,
      });
    });

    return segments;
  }, [quote?.flight_path]);

  const tenderDate = useMemo(() => {
    if (quote?.tender_date_time_of_day) {
      const date = prettyDate(quote?.tender_date);
      const time = getPrettyTimeOfDay(quote?.tender_date_time_of_day as TimeOfDay);
      return `${date} ${time}`;
    }

    return prettyDate(quote?.tender_date);
  }, [quote?.tender_date, quote?.tender_date_time_of_day]);

  const handleSubmit = useCallback(
    async (values: FormValues) => {
      try {
        const normalizedAwb = values.awb_number?.length > 3 ? removeDash(values.awb_number) : null;

        if (!quoteVariant) {
          handleError('Please select a quote variant.');
          return;
        }

        const requestId = quote?.request?.id;
        const quoteId = quote?.id;

        await bookQuote.mutateAsync({
          id: quoteId,
          requestId,
          awbNumber: normalizedAwb,
          customerRef: values.customer_ref,
          additionalInformation: values.additional_information,
          is_pre_screened: values.is_pre_screened,
          tenderDate: values.tender_date as any,
          selectedService: quoteVariant?.type,
          tenderDateTimeOfDay: values.tender_date_time_of_day as any,
          default_contacts: values.default_contacts.map((contact) => ({
            contact_id: Number(contact.contact_id),
            updates_for: contact?.updates_for || [],
          })),
        });

        navigate(`/requests/${requestId}/quotes/${quoteId}/booked`);
      } catch (error) {
        handleError(error);
      }
    },
    [bookQuote, handleError, navigate, quote?.id, quote?.request?.id, quoteVariant],
  );

  return (
    <Page title="Book Quote">
      <Container
        w="full"
        maxW={CONTAINER_2XL}
        pt={{ base: '8', lg: '10' }}
        pb={{ base: '8', lg: '20' }}
        px={{ base: '4', lg: '12' }}
      >
        <Stack spacing="6">
          <Stack justify="space-between" direction="row">
            <Button
              variant="outline"
              size="sm"
              leftIcon={<HiChevronLeft />}
              onClick={() => navigate(`/requests/${quote?.request?.id}`)}
            >
              Quotes
            </Button>
            <Button variant="ghost" size="sm" leftIcon={<HiShare />}>
              Share Listing
            </Button>
          </Stack>
          <SimpleForm onSubmit={handleSubmit} defaultValues={{ default_contacts }} {...(methods as any)}>
            <Stack direction={{ base: 'column', xl: 'row' }} w="full" spacing="6">
              <Stack h="fit-content" w="full">
                <FlightSegmentsCard
                  airlineLogoUri={quote?.airline?.logo_uri}
                  airlineName={quote?.airline?.name}
                  flightSegments={trimmedFlightSegments}
                  estimatedTransitTime={quote?.estimated_transit_time}
                  originAirportId={quote?.origin_airport?.id}
                  destinationAirportId={quote?.destination_airport?.id}
                />
              </Stack>
              <Stack w="full" spacing="6">
                <Card h="auto">
                  <CardBody p="0">
                    <PriceCardTabs
                      defaultTabIndex={tabIndex}
                      onTabChange={handleTabsChange}
                      quoteVariants={quote?.quote_variants}
                      cw={quote?.request?.chargeable_weight}
                      expiresAt={quote?.expires_at}
                      border="none"
                    />
                  </CardBody>
                  <CardFooter borderTopWidth="1px" borderColor="border.default" px="4" pb="3">
                    <BookingForm quote={quote} isBooking={bookQuote.isLoading} />
                  </CardFooter>
                </Card>
                <RequestDetailsCard
                  cargoType={quote?.request?.cargo_type as CargoType}
                  cargo={quote?.request?.cargo as Cargo[]}
                  isKnownShipper={quote?.request?.is_known_shipper}
                  commodity={quote?.request?.commodity}
                  volumetricWeight={quote?.request?.volumetric_weight}
                  grossWeight={quote?.request?.gross_weight}
                  tenderDate={tenderDate}
                  dimensionUnit={quote?.request?.original_dimension_unit as DimensionUnit}
                  weightUnit={quote?.request?.original_weight_unit as WeightUnit}
                />
                <Card h="auto" px="4" py="3">
                  <CardBody p="0">
                    <DefaultContactsCard notificationCategory={NotificationCategory.Booking} />
                  </CardBody>
                </Card>
              </Stack>
            </Stack>
          </SimpleForm>
        </Stack>
      </Container>
      <LinkedRequestModal request={quote?.request} disclosure={linkedRequestModal} />
    </Page>
  );
};
