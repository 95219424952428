import * as z from "zod"
import { SourceType, RateImportState } from "@prisma/client"

// Helper schema for JSON fields
type Literal = boolean | number | string
type Json = Literal | { [key: string]: Json } | Json[]
const literalSchema = z.union([z.string(), z.number(), z.boolean()])
const jsonSchema: z.ZodSchema<Json> = z.lazy(() => z.union([literalSchema, z.array(jsonSchema), z.record(jsonSchema)]))

export const RateImportModel = z.object({
  id: z.number().int(),
  created_at: z.date(),
  updated_at: z.date(),
  airline_id: z.string(),
  source_type: z.nativeEnum(SourceType),
  source: z.string().nullish(),
  source_archive: z.string(),
  name: z.string().nullish(),
  regions: jsonSchema,
  earliest_expiry: z.date().nullish(),
  state: z.nativeEnum(RateImportState),
  supabase_user_id: z.string(),
})
