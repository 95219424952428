import * as z from "zod"
import { CargoType, ServiceType, RateCategory, ExpiryReason, SurchargeCategory } from "@prisma/client"

export const RateModel = z.object({
  id: z.number().int(),
  created_at: z.date(),
  updated_at: z.date(),
  airline_id: z.string(),
  account_id: z.number().int().nullish(),
  airline_product: z.string().nullish(),
  airline_service: z.string().nullish(),
  cargo_type: z.nativeEnum(CargoType),
  service_type: z.nativeEnum(ServiceType),
  rate_category: z.nativeEnum(RateCategory),
  starts_at: z.date(),
  expires_at: z.date().nullish(),
  expiry_reason: z.nativeEnum(ExpiryReason).nullish(),
  expiry_comment: z.string().nullish(),
  replaced_by_id: z.number().int().nullish(),
  origin_airport_id: z.string().nullish(),
  destination_airport_id: z.string().nullish(),
  replacement_initiated_at: z.date().nullish(),
  min_cost: z.number().int().nullish(),
  comments: z.string().nullish(),
  hash: z.string().nullish(),
  surcharge_categories: z.nativeEnum(SurchargeCategory).array(),
  import_id: z.number().int().nullish(),
})
