import * as z from "zod"
import { NotificationCategory } from "@prisma/client"

export const DefaultContactModel = z.object({
  id: z.number().int(),
  created_at: z.date(),
  updated_at: z.date(),
  updates_for: z.nativeEnum(NotificationCategory).array(),
  contact_id: z.number().int(),
  account_id: z.number().int().nullish(),
  request_id: z.number().int().nullish(),
})
