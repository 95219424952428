import { KeysOfUnion, ValueOf } from '@pelicargo/types';
import { HandlingOptions } from '@prisma/client';
import { ZodObjectDef, ZodRawShape } from 'zod';

import { QuoteModel, RequestModel } from '../../../api/src/lib/zod-prisma';
import { getRealType } from './zod';

export enum SCOPES {
  requestClassification = 'requestClassification',
}

export enum DataType {
  String = 'string',
  Number = 'number',
  ListString = 'listString',
  ListNumber = 'listNumber',
  ListEnum = 'listEnum',
  Enum = 'enum',
  Boolean = 'bool', // as the type name should be
}

const generateSource = <T extends ZodRawShape>(modelName: string, def: ZodObjectDef<T>) => {
  const shape = def.shape();
  const enums: Record<string, string[]> = {};
  const source: Record<keyof typeof shape, DataType> = {} as any;
  Object.keys(shape).forEach((attr: keyof typeof shape) => {
    const [name, def] = getRealType(shape[attr]);
    switch (name) {
      case 'ZodString':
        source[attr] = DataType.String;
        return;
      case 'ZodNumber':
        source[attr] = DataType.Number;
        return;
      case 'ZodNativeEnum':
        enums[`${modelName}.${String(attr)}`] = Object.values(def['values']);
        source[attr] = DataType.Enum;
        return;
      case 'ZodBoolean':
        source[attr] = DataType.Boolean;
        return;
      case 'ZodArray': {
        const [arrayName, def] = getRealType(shape[attr]);
        switch (arrayName) {
          case 'ZodString':
            source[attr] = DataType.ListString;
            return;
          case 'ZodNumber':
            source[attr] = DataType.ListNumber;
            return;
          case 'ZodNativeEnum':
            enums[`${modelName}.${String(attr)}`] = Object.values(def['values']);
            source[attr] = DataType.ListEnum;
            return;
        }
        break;
      }
    }
  });
  return { source, enums };
};

const requestShape = RequestModel._def;
const { source: realRequestSource, enums: requestEnums } = generateSource('request', requestShape);

const quoteShape = QuoteModel._def;
const { source: realQuoteSource, enums: quoteEnums } = generateSource('quote', quoteShape);

export const requestSource = {
  ...realRequestSource,
  cargo_count: DataType.Number,
  total_weight: DataType.Number,
  max_weight: DataType.Number,
  max_width: DataType.Number,
  max_height: DataType.Number,
  max_length: DataType.Number,
  widths: DataType.ListNumber,
  heights: DataType.ListNumber,
  lengths: DataType.ListNumber,
  volumes: DataType.ListNumber,
  destination: DataType.ListString,
  origin: DataType.ListString,
  total_volume: DataType.Number,
  max_volume: DataType.Number,
  percent_quotes_fulfilled: DataType.Number,
  quote_count: DataType.Number,
  cargo_handling: DataType.ListEnum,

  // In stasis - currently not in a situation where this matters
  //  minutes_as_current_status: DataType.Number,
};

export const quoteSource = {
  ...realQuoteSource,
  origin: DataType.String,
  destination: DataType.String,
  has_complete_flight_segments: DataType.Boolean,
  // In stasis - currently not in a situation where this matters
  //  minutes_as_current_status: DataType.Number,
};

export const dataSource = {
  request: requestSource,
  quote: quoteSource,
};

export const enumValues = {
  ...requestEnums,
  ...quoteEnums,

  // Due to how things are handled, some unfortunately need to be specified
  'request.cargo_handling': Object.values(HandlingOptions),
};

export const groupOperations = {
  all: 'All of the following must be true',
  some: 'At least one of the following must be true',
  one: 'Only one of the following must be true',
  none: 'None of the following must be true',
};

export const groupOperationBorders: Record<keyof typeof groupOperations, string> = {
  all: '2px solid green',
  some: '2px dashed green',
  one: '2px dotted orange',
  none: '2px solid red',
};

export enum CheckType {
  group = 'group',
  data = 'data',
}

export type ValueArgs = {
  source: keyof typeof dataSource;
  value: string;
  comparison: string;
};

export type Condition = {
  type: CheckType.group;
  func: string;
  args: Condition[] | ValueArgs;
};

// List operations
const one: <T>(list: T[], checker: (T) => boolean) => boolean = (list, checker) =>
  list.map(checker).filter((x) => x).length === 1;

const some: <T>(list: T[], checker: (T) => boolean) => boolean = (list, checker) =>
  list.map(checker).filter((x) => x).length > 0;

const all: <T>(list: T[], checker: (T) => boolean) => boolean = (list, checker) =>
  list.map(checker).filter((x) => x).length === list.length;

const none: <T>(list: T[], checker: (T) => boolean) => boolean = (list, checker) =>
  list.map(checker).filter((x) => x).length === 0;

const numberLogic = {
  equals: (a: number, b: number) => {
    return a === b;
  },
  notEquals: (a: number, b: number) => {
    return a !== b;
  },
  lessThan: (a: number, b: number) => {
    return a < b;
  },
  lessThanOrEqual: (a: number, b: number) => {
    return a <= b;
  },
  greaterThan: (a: number, b: number) => {
    return a > b;
  },
  greaterThanOrEqual: (a: number, b: number) => {
    return a >= b;
  },
} as const;

const stringLogic = {
  equals: (a: string, b: string) => {
    return a === b;
  },
  notEquals: (a: string, b: string) => {
    return a !== b;
  },
  contains: (a: string, b: string) => {
    return a.indexOf(b) !== -1;
  },
} as const;

// Logic that works with the concept of lists
const listLogic = {
  item_count_greater_than: (list: any[], _operation: any, comparator: any) => {
    return numberLogic['greaterThan'](list.length, parseInt(comparator));
  },
  item_count_less_than: (list: any[], _operation: any, comparator: any) => {
    return numberLogic['lessThan'](list.length, parseInt(comparator));
  },
  item_count_equals: (list: any[], _operation: any, comparator: any) => {
    return numberLogic['equals'](list.length, parseInt(comparator));
  },
} as const;

const listNumberLogic = {
  all: (list: number[], operation: keyof typeof numberLogic, comparator: number) =>
    all(list, (n) => numberLogic[operation](n, comparator)),
  some: (list: number[], operation: keyof typeof numberLogic, comparator: number) =>
    some(list, (n) => numberLogic[operation](n, comparator)),
  none: (list: number[], operation: keyof typeof numberLogic, comparator: number) =>
    none(list, (n) => numberLogic[operation](n, comparator)),
  ...listLogic,
} as const;

const listStringLogic = {
  all: (list: string[], operation: keyof typeof stringLogic, comparator: string) =>
    all(list, (n) => stringLogic[operation](n, comparator)),
  some: (list: string[], operation: keyof typeof stringLogic, comparator: string) => {
    return some(list, (n) => stringLogic[operation](n, comparator));
  },
  none: (list: string[], operation: keyof typeof stringLogic, comparator: string) =>
    none(list, (n) => stringLogic[operation](n, comparator)),
  ...listLogic,
} as const;

const booleanLogic = {
  equals: (a: boolean, b: boolean) => a === b,
};

export const valueLogic = {
  [DataType.Enum]: stringLogic,
  [DataType.String]: stringLogic,
  [DataType.Number]: numberLogic,
  [DataType.ListNumber]: listNumberLogic,
  [DataType.ListString]: listStringLogic,
  [DataType.Boolean]: booleanLogic,
  // Enums are just strings under the hood
  [DataType.ListEnum]: listStringLogic,
} as const;

export const defaultValues: {
  [K in keyof typeof valueLogic]: {
    func: keyof (typeof valueLogic)[K];
    comparison?: string;
  };
} = {
  [DataType.Enum]: { func: 'equals', comparison: '' },
  [DataType.String]: { func: 'equals', comparison: '' },
  [DataType.Number]: { func: 'equals', comparison: '0' },
  [DataType.ListNumber]: { func: 'all', comparison: '' },
  [DataType.ListString]: { func: 'all', comparison: '' },
  [DataType.Boolean]: { func: 'equals', comparison: 'true' },
  [DataType.ListEnum]: { func: 'all', comparison: '' },
} as const;

export const groupLogic = {
  all,
  some,
  one,
  none,
} as const;

export type CompiledValueType = {
  type: CheckType.data;
  func: KeysOfUnion<ValueOf<typeof valueLogic>>;
  args: ValueArgs;
};

export type CompiledGroupType = {
  type: CheckType.group;
  func: keyof typeof groupLogic;
  args: CompiledType[];
};

export type CompiledType = CompiledValueType | CompiledGroupType;
