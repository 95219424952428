import * as z from "zod"

export const AirportSurchargeModel = z.object({
  id: z.number().int(),
  created_at: z.date(),
  updated_at: z.date(),
  order: z.number().int().nullish(),
  airport_as_destination_id: z.string().nullish(),
  airport_as_origin_id: z.string().nullish(),
  surcharge_for_destination_id: z.number().int().nullish(),
  surcharge_for_origin_id: z.number().int().nullish(),
})
