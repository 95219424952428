import * as z from "zod"
import { VariantType } from "@prisma/client"

export const QuoteVariantModel = z.object({
  id: z.number().int(),
  created_at: z.date(),
  updated_at: z.date(),
  name: z.string(),
  type: z.nativeEnum(VariantType),
  airline_ref: z.string().nullish(),
  quote_id: z.number().int(),
})
