import * as z from "zod"
import { RateLimitBucketType } from "@prisma/client"

export const RateLimitModel = z.object({
  id: z.number().int(),
  created_at: z.date(),
  updated_at: z.date(),
  bucket: z.nativeEnum(RateLimitBucketType),
  refresh_at: z.date(),
  remaining: z.number().int(),
  api_key_id: z.number().int(),
})
