import * as z from "zod"
import { AircraftType } from "@prisma/client"

export const AircraftModel = z.object({
  id: z.number().int(),
  created_at: z.date(),
  updated_at: z.date(),
  name: z.string(),
  type: z.nativeEnum(AircraftType).nullish(),
  max_length: z.number().nullish(),
  max_width: z.number().nullish(),
  max_height: z.number().nullish(),
  max_weight: z.number().nullish(),
})
