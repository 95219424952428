import * as z from "zod"

export const FulfillmentPartnerConfigurationModel = z.object({
  id: z.number().int(),
  created_at: z.date(),
  updated_at: z.date(),
  label: z.string(),
  account_as_fulfillment_partner_id: z.number().int(),
  is_allowed_all_origins: z.boolean().nullish(),
  is_allowed_all_airlines: z.boolean().nullish(),
  is_allowed_all_requestors: z.boolean().nullish(),
  is_restricted: z.boolean(),
})
