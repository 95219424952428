import * as z from "zod"
import { ContactType } from "@prisma/client"

export const ContactModel = z.object({
  id: z.number().int(),
  created_at: z.date(),
  updated_at: z.date(),
  first_name: z.string().nullish(),
  last_name: z.string().nullish(),
  title: z.string().nullish(),
  phone_number: z.string().nullish(),
  email: z.string().nullish(),
  notes: z.string().nullish(),
  tags: z.string().array(),
  full_name: z.string().nullish(),
  preferred_name: z.string().nullish(),
  contact_type: z.nativeEnum(ContactType),
  account_id: z.number().int().nullish(),
  airline_id: z.string().nullish(),
  airport_id: z.string().nullish(),
})
