import * as z from "zod"
import { SurchargeCategory, CargoType, ServiceType, PerUnitType, SurchargeUnit, SurchargeWeightType } from "@prisma/client"

export const SurchargeModel = z.object({
  id: z.number().int(),
  created_at: z.date(),
  updated_at: z.date(),
  airline_id: z.string(),
  airline_label: z.string().nullish(),
  airline_code: z.string().nullish(),
  airline_product: z.string().nullish(),
  surcharge_category: z.nativeEnum(SurchargeCategory),
  cargo_type: z.nativeEnum(CargoType).nullish(),
  service_type: z.nativeEnum(ServiceType).nullish(),
  is_optional: z.boolean(),
  min_cost: z.number().int().nullish(),
  per_unit_cost: z.number().int().nullish(),
  per_unit_type: z.nativeEnum(PerUnitType),
  unit: z.nativeEnum(SurchargeUnit),
  starts_at: z.date(),
  expires_at: z.date().nullish(),
  weight_type: z.nativeEnum(SurchargeWeightType),
  comments: z.string().nullish(),
  replaced_by_id: z.number().int().nullish(),
  account_id: z.number().int().nullish(),
})
