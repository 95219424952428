import * as z from "zod"

export const AccountInviteModel = z.object({
  id: z.number().int(),
  created_at: z.date(),
  updated_at: z.date(),
  email: z.string(),
  token: z.string(),
  accepted_at: z.date().nullish(),
  account_id: z.number().int(),
})
