import * as z from "zod"
import { AccountType, SurchargeCategory } from "@prisma/client"

export const AirlineModel = z.object({
  id: z.string(),
  created_at: z.date(),
  updated_at: z.date(),
  name: z.string(),
  logo_uri: z.string().nullish(),
  awb_prefix: z.string().nullish(),
  is_active: z.boolean(),
  supported_account_types: z.nativeEnum(AccountType).array(),
  required_surcharge_categories: z.nativeEnum(SurchargeCategory).array(),
})
