import * as z from "zod"

export const AccountUserModel = z.object({
  id: z.number().int(),
  created_at: z.date(),
  updated_at: z.date(),
  account_id: z.number().int(),
  user_id: z.number().int().nullish(),
  preferred_by_user_id: z.number().int().nullish(),
})
