import * as z from "zod"
import { RateImportAction, ExpiryReason } from "@prisma/client"

export const RateImportBulkActionModel = z.object({
  id: z.number().int(),
  created_at: z.date(),
  updated_at: z.date(),
  rate_import_id: z.number().int(),
  action: z.nativeEnum(RateImportAction),
  previous_expiry: z.date(),
  next_expiry: z.date(),
  previous_reason: z.nativeEnum(ExpiryReason).nullish(),
  next_reason: z.nativeEnum(ExpiryReason).nullish(),
  previous_comment: z.string().nullish(),
  next_comment: z.string().nullish(),
})
