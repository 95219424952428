import { Card, CardBody, Heading, Stack, Text } from '@chakra-ui/react';

type Props = {
  instructions: string;
};

export const InstructionsCard = ({ instructions }: Props) => {
  return (
    <Card h="auto" px="4" py="3">
      <CardBody p="0">
        <Stack spacing="3">
          <Heading variant="h5">Instructions</Heading>
          <Text textStyle="bodyRegular" whiteSpace="pre-line">
            {instructions}
          </Text>
        </Stack>
      </CardBody>
    </Card>
  );
};
