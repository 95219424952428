import * as z from "zod"
import { WeightUnit, DimensionUnit, CargoTemperatureRange, CargoCooling, CargoType, PackingGroup, AircraftVariant, PackingInstruction, Section, BatteryType, AircraftType, RequestStatus, FreightStatus } from "@prisma/client"

// Helper schema for JSON fields
type Literal = boolean | number | string
type Json = Literal | { [key: string]: Json } | Json[]
const literalSchema = z.union([z.string(), z.number(), z.boolean()])
const jsonSchema: z.ZodSchema<Json> = z.lazy(() => z.union([literalSchema, z.array(jsonSchema), z.record(jsonSchema)]))

export const RequestModel = z.object({
  id: z.number().int(),
  created_at: z.date(),
  updated_at: z.date(),
  cargo_origin: jsonSchema,
  arrive_by: z.date().nullish(),
  dropoff_by: z.date().nullish(),
  confirmed_at: z.date().nullish(),
  gross_weight: z.number().nullish(),
  chargeable_weight: z.number().nullish(),
  volumetric_weight: z.number().nullish(),
  additional_information: z.string().nullish(),
  requires_direct_flight: z.boolean().nullish(),
  awb_number: z.string().nullish(),
  account_ref: z.string().nullish(),
  service_type: z.string().array(),
  is_pre_screened: z.boolean().nullish(),
  is_known_shipper: z.boolean().nullish(),
  original_weight_unit: z.nativeEnum(WeightUnit),
  original_dimension_unit: z.nativeEnum(DimensionUnit),
  cargo_details: z.string().nullish(),
  cargo_temperature_range: z.nativeEnum(CargoTemperatureRange).nullish(),
  cargo_cooling: z.nativeEnum(CargoCooling).nullish(),
  cargo_type: z.nativeEnum(CargoType).nullish(),
  commodity: z.string().nullish(),
  target_rate: z.number().int().nullish(),
  un_number: z.number().int().nullish(),
  class: z.number().nullish(),
  packing_group: z.nativeEnum(PackingGroup).nullish(),
  aircraft_variant: z.nativeEnum(AircraftVariant).nullish(),
  packing_instruction: z.nativeEnum(PackingInstruction).nullish(),
  section: z.nativeEnum(Section).nullish(),
  battery_type: z.nativeEnum(BatteryType).nullish(),
  aircraft_types: z.nativeEnum(AircraftType).array(),
  customer_ref: z.string().nullish(),
  status: z.nativeEnum(RequestStatus),
  status_last_updated_at: z.date().nullish(),
  freight_status: z.nativeEnum(FreightStatus).nullish(),
  is_subscribed: z.boolean(),
  is_llm_generated: z.boolean().nullish(),
  account_id: z.number().int().nullish(),
  author_id: z.number().int().nullish(),
  default_sender_contact_id: z.number().int().nullish(),
  booked_quote_variant_id: z.number().int().nullish(),
  booked_at: z.date().nullish(),
  main_request_id: z.number().int().nullish(),
})
