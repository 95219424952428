import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Button,
  Flex,
  Stack,
  Tag,
  TagLabel,
  TagLeftIcon,
} from '@chakra-ui/react';
import { CargoType, DocumentType } from '@pelicargo/types';
import { Show } from '@pelicargo/ui';
import { FocusEventHandler, useCallback, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { HiOutlineExclamation } from 'react-icons/hi';

import { API } from '../../utils/api-typings';
import { formatAwbNumber } from '../../utils/inputFormatters';
import { SimpleCheckbox } from '../SimpleCheckbox';
import { SimpleDatePickerInput } from '../SimpleDatePickerInput';
import { SimpleDropzone } from '../SimpleDropzone';
import { SimpleInput } from '../SimpleInput';
import { SimpleSwitch } from '../SimpleSwitch';
import { SimpleTextarea } from '../SimpleTextarea';

type Props = {
  quote: API.GetQuote;
  isBooking: boolean;
};

export const BookingForm = ({ quote, isBooking = false }: Props) => {
  const methods = useFormContext();

  const requestId = quote?.request_id;
  const request = quote?.request;
  const awbPrefix = quote?.airline?.awb_prefix;
  const airlineId = quote?.airline?.name;
  const expiresAt = quote?.expires_at;

  const documentsInForm = methods.watch('documents');

  const isDangerousGoods = useMemo(() => request?.cargo_type === CargoType.DangerousGoods, [request?.cargo_type]);
  const shouldShowDgdWarning = useMemo(() => {
    const hasDocument = documentsInForm?.length > 0;

    if (isDangerousGoods && !hasDocument) return true;
    return false;
  }, [documentsInForm, isDangerousGoods]);

  const isExpired = useMemo(() => {
    if (!expiresAt) return false;

    const convertToTZ = (date: Date, timeZone: string) => {
      const newDate = new Date(date.toLocaleString('en-US', { timeZone }));
      return newDate;
    };
    const originTimeZone = quote?.origin_airport?.timeZone;
    const adjustedExpiresAt = convertToTZ(expiresAt, originTimeZone);
    adjustedExpiresAt.setHours(23, 59, 59, 999);
    const now = convertToTZ(new Date(), originTimeZone);

    return adjustedExpiresAt < now;
  }, [expiresAt, quote?.origin_airport?.timeZone]);

  const [awbWarning, setAwbWarning] = useState('');

  const checkAWB: FocusEventHandler<HTMLInputElement> = useCallback(
    (event) => {
      const input = event?.target?.value;
      const hasAwbPrefix = !!awbPrefix;
      const hasInvalidPrefix = input?.length >= 3 && input?.slice(0, 3) !== awbPrefix;

      if (hasInvalidPrefix && hasAwbPrefix) {
        setAwbWarning(`Expecting AWB for ${airlineId} to start with ${awbPrefix}. Are you sure this is correct?`);
      } else setAwbWarning('');
    },
    [airlineId, awbPrefix],
  );

  return (
    <Stack w="full" spacing="3">
      <Stack direction={{ base: 'column', md: 'row', xl: 'column' }} w="full">
        <Stack direction={{ base: 'column', sm: 'row' }} w="full">
          <SimpleInput
            label="AWB #"
            name="awb_number"
            placeholder="x x x | x x x x x x x x"
            maxLength={12}
            variant="outline"
            w="full"
            formatter={formatAwbNumber}
            onBlur={checkAWB}
            errorMessage={awbWarning}
          />
          <SimpleDatePickerInput
            name="tender_date"
            label="Tender Date *"
            variant="outline"
            matchWidth={false}
            timeOfDay
            timeOfDayName="tender_date_time_of_day"
            iconFontSize="20px"
          />
        </Stack>
        <Stack direction={{ base: 'column', sm: 'row' }} w="full">
          <SimpleSwitch
            name="is_pre_screened"
            label="Pre-screened"
            options={[
              { label: 'Yes', value: true },
              { label: 'No', value: false },
            ]}
            flex="1"
            variant="outline"
          />
          <SimpleInput
            label="Your Reference #"
            name="customer_ref"
            placeholder="x x x   x x x   x x x"
            variant="outline"
            flex="1"
          />
        </Stack>
      </Stack>
      <SimpleTextarea
        name="additional_information"
        label="Additional Notes"
        placeholder="Additional notes"
        variant="outline"
        rows={2}
      />
      <Show if={shouldShowDgdWarning}>
        <Flex>
          <Tag size="lg" borderRadius="full" variant="outline" colorScheme="red" py="2">
            <TagLeftIcon as={HiOutlineExclamation} boxSize="6" />
            <TagLabel fontSize="lg">Missing Dangerous Goods Declaration - Upload to Book</TagLabel>
          </Tag>
        </Flex>
      </Show>
      <Show if={isDangerousGoods}>
        <SimpleDropzone name="documents" documentType={DocumentType.DGD} requestId={requestId} />
      </Show>
      <Show if={isExpired}>
        <Flex>
          <Alert status="error" variant="subtle" fontSize="sm" borderRadius="md">
            <AlertIcon />
            <Stack spacing="1">
              <AlertTitle>Quote Expired</AlertTitle>
              <AlertDescription>
                This quote has passed its expiry date. Booking this may not result in the same price.
              </AlertDescription>
            </Stack>
          </Alert>
        </Flex>
      </Show>
      <SimpleCheckbox
        name="terms"
        label="By checking, I accept the airline's conditions of contract"
        variant="unstyled"
      />
      <Stack>
        <Button colorScheme="primary" size="lg" type="submit" isLoading={isBooking} isDisabled={shouldShowDgdWarning}>
          Request Booking
        </Button>
      </Stack>
    </Stack>
  );
};
