import * as z from "zod"
import { MarkupCategory, LineItemType } from "@prisma/client"

export const MarkupModel = z.object({
  id: z.number().int(),
  created_at: z.date(),
  updated_at: z.date(),
  label: z.string(),
  category: z.nativeEnum(MarkupCategory),
  min_cost: z.number().int(),
  line_item_types: z.nativeEnum(LineItemType).array(),
  fulfillment_partner_configuration_id: z.number().int(),
})
