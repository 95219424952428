import * as z from "zod"
import { HandlingOptions } from "@prisma/client"

export const CargoModel = z.object({
  id: z.number().int(),
  created_at: z.date(),
  updated_at: z.date(),
  handling: z.nativeEnum(HandlingOptions).array(),
  quantity: z.number().int(),
  width: z.number(),
  height: z.number(),
  length: z.number(),
  weight: z.number().nullish(),
  request_id: z.number().int(),
})
